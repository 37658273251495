<template>

  <b-card-code
    title="Profile"
  >
  <ValidationObserver v-slot="{ handleSubmit}">
  <b-form @submit.prevent="handleSubmit(storeTeacherProfile)">
    
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            label="Nombre"
            label-for="mc-first-name"
          >
            <ValidationProvider rules="required" name="Nombre" v-slot="{ errors }">
              <b-form-input
                v-model = "teacherProfile.name"
                id="mc-first-name"
                placeholder="Nombre"
                name="name"
              />
              <span class="field-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Apellidos"
            label-for="mc-last-name"
          >
          <ValidationProvider rules="required" name="Apellidos" v-slot="{ errors }">
            <b-form-input
              v-model = "teacherProfile.surname"
              id="mc-last-name"
              placeholder="Apellidos"
            />
            <span class="field-error">{{ errors[0] }}</span>
          </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Telefono"
            label-for="mc-city"
          >
            <ValidationProvider rules="required" name="Telefono" v-slot="{ errors }">
              <b-form-input
                v-model = "teacherProfile.telephone_num"
                id="mc-city"
                placeholder="Telefono"
              />
              <span class="field-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mc-email"
            label="Email"
          >
            <div class="form-label-group">
              <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
                <b-form-input
                  v-model = "teacherProfile.email"
                  id="mc-email"
                  type="email"
                  placeholder="Email"
                />
                <span class="field-error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </b-form-group>
        </b-col>
        
        <!-- submit and reset -->
        <b-col>
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            
          >
            Submit
          </b-button>
         
        </b-col>
      </b-row>
    </b-form>
  </ValidationObserver> 
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BCol, BAvatar,BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ProfileService from '@/services/profile.service.js';
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BAvatar,
    BCardText,
    BFormDatepicker
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      teacherProfile: {
        name: '',
        surname: '',
        email: '',
        telephone_num: '',
        
      },
      logoImg: require('@/assets/images/pages/logo-1.png'),
      avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
    }
  },
  methods: {
    
    storeTeacherProfile() {
          let getData = this.storeFormData();
          ProfileService.storeTeacherProfile(getData).then(response => {
                console.log(response)
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: 'Form Submitted',
                //     icon: 'EditIcon',
                //     variant: 'success',
                //   },
                // })
                
            }).catch(error => {
                console.log(error)
            });
          
          
        
    },
    storeFormData()
    {
      let formData = new FormData();
      for(let teacherKey in this.teacherProfile) {
          formData.append(teacherKey, this.teacherProfile[teacherKey]);
      }
      return formData;
    },
  },

  created() {
    if(this.$store.getters.getUser) {
      this.teacherProfile.email = this.$store.getters.getUser.email;
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
